import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Link from '../components/Link'
import classNames from 'classnames'
import styles from './index.module.scss'

const title = 'Zero Technology – Product Development Agency'
const description = 'Founders and business leaders trust us to create their digital products and ventures.'
const image = 'https://www.zero.london/ucsd-cmu-sleep@3x.png'
const imageAlt = 'A mobile app created by Zero'
const imageDimensions = [1251, 1296]

export default ({ location }) => (
  <Layout location={location} footer home>
    <Helmet>
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image' content={image} />
      <meta name='twitter:image:alt' content={imageAlt} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={image} />
      <meta property='og:image:alt' content={imageAlt} />
      <meta property='og:image:width' content={imageDimensions[0]} />
      <meta property='og:image:height' content={imageDimensions[1]} />
    </Helmet>
    <div className={styles.Hero}>
      <div className={styles.HeroContent}>
        <h1 className={styles.Title}>Don’t get left behind. Fast-track your product development process.</h1>
        <h2 className={styles.Subtitle}>
          Founders and business leaders trust us to create their digital products and ventures.
        </h2>
        <Link large button to='/contact'>
          Get our view on your project
        </Link>
      </div>

      <div className={styles.Section}>
        <div className={styles.SectionTitle}>This is what we’re really good at</div>
        <h3>Definition and strategy</h3>
        <p>We help align business objectives with product requirements</p>

        <h3>Design</h3>
        <p>We define how your product would work and look, and how it would be used</p>

        <h3>Development</h3>
        <p>We create quality code ready to scale and launch to market</p>
      </div>

      <div className={styles.Case}>
        <div className={styles.Pill}>Featured project</div>
        <div className={styles.CaseContent}>
          <div className={styles.CaseDescription}>
            <div>
              <div className={classNames(styles.SectionTitle, styles.desktop)}>
                University of California and Carnegie Mellon
              </div>
              <div className={classNames(styles.SectionTitle, styles.mobile)}>University of California and CMU</div>
              <h3>Improving students' performance</h3>
              <p>
                We partnered to define, design, build and launch a mobile application across both iOS and Android to
                support a research project with the goal of helping students to improve their academic performance.
              </p>
              <Link button large to='/work/ucsd-cmu-sleep'>
                Learn more
              </Link>
            </div>
          </div>
          <div className={styles.CaseScreenshots}>
            <div className={styles.CaseScreenshot}>
              <img
                alt='University of California and Carnegie Mellon Mobile App Screenshots'
                srcSet={`
                ucsd-cmu-sleep.png 1x,
                ucsd-cmu-sleep@2x.png 2x,
                ucsd-cmu-sleep@3x.png 3x
              `.replace(/\n/g, ' ')}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.Section}>
        <div className={styles.SectionTitle}>The type of clients we drive value for</div>
        <h3>Entrepreneurs and founders</h3>
        <p>We bring clarity and help figure things out fast </p>
        <Link subtle to='/work/manatee-app'>
          We enabled Manatee to improve children’s mental health
        </Link>

        <h3>Small to medium businesses</h3>
        <p>We enhance capabilities and evolve their products</p>
        <Link subtle to='/work/catapult-ecommerce'>
          We helped Catapult drive more revenue
        </Link>

        <h3>Large and Enterprise</h3>
        <p>We facilitate innovation and bypass slow processes</p>
        <Link subtle to='/work/ucsd-cmu-sleep'>
          We helped UCSD and CMU run a research project at scale
        </Link>
      </div>

      <div className={styles.FinalSection}>
        <h4>Are you an ambitious entrepreneur or business leader?</h4>
        <Link large button to='/contact'>
          Get our view on your project
        </Link>
      </div>
    </div>
  </Layout>
)
